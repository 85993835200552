.C-container{
    justify-content: space-around;
}


.C-left{
    gap: 0.5rem;
}
.contactModes{
    margin-top: 2rem;
    gap: 1rem;
    display: flex;
}
.contactModes.row{
    gap: 1.5rem;
}
.mode{
    width: 16rem;
    padding: 1rem;
    border: 0.8px solid rgba(128,128,128,0.143);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
}
.mode .button{
    width: 100%;
    background: var(--lightBlue);
    color:var(--blue);
    font-size: 0.9rem;
    font-weight: 600;
}
.mode >:nth-child(1){
    width: 100%;
    gap: 1.6rem;
}
.mode .primaryText{
    font-size: 15px;
}
.mode:hover{
    scale: 1.1;
    box-shadow: var(--shadow);
}
.mode .button:hover{
    background: var(--blue-gradient);
    color: white;
    scale: 0.8;
}
@media(max-width:1024px){
    .C-container{
        flex-direction: column;
        
       
    }
    .C-right{
        justify-content: center;
    }
    .row{
        flex-direction: column;
        width: 100%;
    } 
}