
.inner-container{
    gap: 1.5rem;
    background: var(--blue);
    padding: 2rem;
    border: 6px solid lightblue;
    border-radius: 10px;
   
}
.inner-container .primaryText{
    color: whitesmoke;
    font-weight: 500;
}
.inner-container .secondaryText{
    color: whitesmoke;
    font-weight: 300;
}
.inner-container .button{
    /* padding: 4px; */
    background:#5a73d7;
    border: 2px solid white;
    border-radius: 10px;
}