.f-container{
    justify-content: space-around;
}
.f-left{
    gap: 1rem;
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 500;
}